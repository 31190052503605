import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import get from 'lodash/get'
// import EventCard from '../components/events/card'
import CategoryNav from '../components/categories/nav'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import ArrowLink from '../components/misc/arrow-link'
import LayoutContext from '../components/layout/context'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class PartnerUpload extends React.Component {
  static contextType = LayoutContext
  constructor(props) {
    super(props)

    const schools = get(props, 'data.allContentfulSchool.edges')

    this.state = {
      schools,
      name: '',
      hbcu: '',
      title: '',
      termsAccepted: false,
      validated: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillMount() {
    this.context.set({
      meta: get(this, 'props.data.contentfulMetaTags'),
    })
  }

  handleInputChange(e) {
    const { value, name } = e.target

    this.setState({
      [name]: value,
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()
    const form = e.currentTarget

    if (form.checkValidity() === false) {
      this.setState({ validated: true })
    } else {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          name: this.state.name,
          title: this.state.title,
          hbcu: this.state.hbcu,
          termsAccepted: this.state.termsAccepted,
        }),
      })
        .then(() => {
          let uploadURL = ''
          this.state.schools.forEach(({ node }, i) => {
            if (node.name === this.state.hbcu) {
              uploadURL = node.contentSubmissionUrl
            }
          })
          window.location.href = uploadURL
        })
        .catch((error) => alert(error))
    }
  }

  render() {
    return (
      <>
        <Container className="py-5 bg-white">
          <Row>
            <Col>
              <h2 className="hu">One Yard HBCU Submission Guidelines</h2>
              <ol type="i">
                <li>
                  Content must be original and cannot include copyrighted
                  materials owned by others;
                </li>
                <li>
                  Permissions must be secured from all individuals appearing in
                  the Content; and
                </li>
                <li>
                  Submitter has the authority and permission to submit the
                  Content on behalf of the HBCU identified below.{' '}
                </li>
              </ol>

              <h4>Submission Terms and Conditions</h4>
              <p>
                By submitting audio files ("Music"), photos ("Photos"), images
                ("Images"), text ("Text"), and videos ("Videos") (collectively,
                "<strong>Content</strong>") on behalf of the Historically Black
                College and University ("HBCU") identified below, to Interactive
                One, LLC. ("Interactive One"), for inclusion within the One Yard
                virtual HBCU Homecoming digital and live streaming experience
                ("One Yard") you agree to the following terms ("
                <strong>Submission Terms</strong>"). You also agree to abide by
                the Terms of Service found at{' '}
                <a
                  href="https://ionedigital.com/terms-of-service"
                  target="_blank"
                >
                  https://ionedigital.com/terms-of-service
                </a>
                .
              </p>
              <p>
                By submitting the Content you represent and warrant that: i) you
                are 18 years of age or older; ii) you own or control all right,
                title and interest in and to the Content, including, but not
                limited to, all copyrights; iii) the Content and the
                distribution of the Content does not and will not infringe or
                violate the rights of any third party, including, without
                limitation, any property rights, music publishing rights,
                performers' rights, rights of privacy or publicity, or rights in
                confidential information; iii) you have obtained any and all
                necessary consents, permissions and/or releases from any and all
                persons appearing in the Content. In consideration of the
                possibility of favorable publicity and exposure that may arise
                from submitting the Content, you hereby grant Interactive One
                together with its subsidiaries, affiliates, licensees,
                successors, and assigns a non-exclusive, transferable,
                sub-licensable, royalty-free, worldwide, irrevocable right and
                license to use, reproduce, modify, edit, publish, distribute,
                publicly display, perform, transmit, remove, retain, create
                derivative works out of, and commercialize your Content as part
                of One Yard in any form, media, or technology now known or
                hereafter devised, without obtaining additional consent, without
                restriction, notification, or attribution, and without any
                further compensation to you and to authorize others to do the
                same. Urban One has no obligation to feature your Content.
                Furthermore, no royalties, fees, or payment shall be due from
                Urban One to you or to any third party, in connection with Urban
                One's use of the Content.
              </p>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md="6">
              <Form
                name="partner-upload"
                noValidate
                validated={this.state.validated}
                onSubmit={this.handleSubmit}
                data-netlify="true"
              >
                <Form.Group controlId="hbcu">
                  <Form.Label>Submission is on behalf of:</Form.Label>
                  <Form.Control
                    as="select"
                    name="hbcu"
                    value={this.state.hbcu}
                    required
                    onChange={this.handleInputChange}
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    {this.state.schools.map(({ node }) => (
                      <option key={node.slug} value={node.name}>
                        {node.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select the school you represent.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="name">
                  <Form.Label>Submission is made by:</Form.Label>
                  <Form.Control
                    placeholder="Your Name"
                    name="name"
                    required
                    value={this.state.name}
                    onChange={this.handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="name">
                  <Form.Control
                    placeholder="Your Title"
                    name="title"
                    required
                    value={this.state.title}
                    onChange={this.handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your job title.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="termsAccepted">
                  <Form.Text>
                    By checking this box you agree to these Submission Terms and
                    Interactive One{' '}
                    <a
                      href="https://ionedigital.com/terms-of-service"
                      target="_blank"
                    >
                      Terms of Service
                    </a>
                    . If these Submission Terms conflict with Interactive One's
                    Terms of Service these Submission Terms will control with
                    respect to only the term, or terms in conflict.{' '}
                  </Form.Text>
                  <Form.Check
                    type="checkbox"
                    name="termsAccepted"
                    label="I agree"
                    onChange={this.handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    You must agree to the terms and conditions in order to
                    proceed.
                  </Form.Control.Feedback>
                </Form.Group>
                <div data-netlify-recaptcha="true"></div>
                <Button variant="primary" type="submit" size="lg">
                  Proceed to Upload
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
        <CategoryNav />
      </>
    )
  }
}

export default PartnerUpload

export const pageQuery = graphql`
  query PartnerUploadQuery {
    contentfulMetaTags(pagePath: { eq: "/partner-upload" }) {
      title
      image {
        gatsbyImageData(width: 1200, height: 630)
      }
      url
      description {
        description
      }
    }
    allContentfulSchool(
      filter: {
        contentful_id: { ne: "5qXVQWEerwQDygLHj9QNAo" }
        partner: { eq: true }
        contentSubmissionUrl: { ne: null }
      }
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          name
          slug
          contentSubmissionUrl
        }
      }
    }
  }
`
